import React, { useState, useEffect } from 'react';
import { Badge, Typography, Carousel, Image, Button } from 'antd';
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';
import tickAsset1 from '../../assets/stick1.png';
import tickAsset2 from '../../assets/stick2.png';
import aboutImage from '../../assets/about.png';
import pkkwImage from '../../assets/pkkw.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notification } from '../../components';
import home from '../../assets/banner.jpg';
import icon1 from '../../assets/icon_profit_borrow1.svg';
import icon2 from '../../assets/icon_profit_borrow2.svg';
import icon3 from '../../assets/icon_profit_borrow3.svg';
import icon4 from '../../assets/icon_profit_borrow4.svg';
import icon5 from '../../assets/icon_profit_borrow5.svg';
import connectCSKH from '../../utils/connectCSKH';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
const arrayText = [
  '097***4 đã rút 35.000.000 đ',
  '033***8 đã rút 30.000.000 đ',
  '087***3 đã rút 45.000.000 đ',
  '033***1 đã rút 15.000.000 đ',
  '035***5 đã được duyệt hồ sơ',
  '033***5 đã rút 18.000.000 đ',
  '034***2 đã rút 13.000.000 đ',
  '090***3 đã rút 29.000.000 đ',
  '087***5 đã được duyệt hồ sơ',
  '089***8 đã rút 50.000.000 đ',
  '076***5 đã rút 45.000.000 đ',
  '078***0 đã rút 17.000.000 đ',
  '038***5 đã được duyệt hồ sơ',
  '093***6 đã rút 16.000.000 đ',
  '077***9 đã rút 15.000.000 đ',
  '070***4 đã rút 19.000.000 đ',  
];

export default function Home() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < arrayText.length - 1)
        setCurrentIndex((prev) => prev + 1);
      else setCurrentIndex((prev) => 0);
    }, 1800);
    return () => clearInterval(interval);
  });
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="container"
    >
      <div className="header-content">
        <div>
          <Typography.Text className="text-head">Xin chào! </Typography.Text>
          <br />
          <Typography.Text className="text-head">
            {profile?.kyc?.name || profile.phone}
          </Typography.Text>
        </div>
        <motion.div
          whileTap={{ scale: 0.9, opacity: 0.7 }}
          onClick={() => history.push('/notifications')}
        >
          <Notification />
        </motion.div>
      </div>
	
      <div className="noti-text-container">
        <Typography.Text className="noti-text" style={{ color: '#ff4d4f' }}>
          {arrayText[currentIndex]}
        </Typography.Text>
      </div>
	  <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
		  color: '#ff0021',
		  fontSize: 18,
		  fontWeight: 800
        }}
      >
          LINH HOẠT KỲ HẠN, TRẢ GÓP DỄ DÀNG
      </div>
      <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider4">
            <div className="sl-text-container"></div>
          </div>
        </div>
      </Carousel>
	  <Button
        className="ant-btn ant-btn-default choose-btn  blink-animation"
        onClick={() => {
          history.push('/vay');
        }}
      >
        <Typography.Text
          style={{ color: '#fff', fontSize: 18 }}
        >
          👉 Đăng ký vay ngay 👈
        </Typography.Text>
      </Button>
	  <div
        style={{
          background: 'rgb(237 246 253)',		  
		  paddingTop: 10
        }}
      >
          <div
			style={{
			  padding: 10,
			  display: 'flex',
			  justifyContent: 'center',
			  alignItems: 'center',
			  flexDirection: 'column',
			  color: '#ff0021',
			  fontSize: 18,
			  fontWeight: 800
			}}
		  >
			  MÔ TẢ SẢN PHẨM
		  </div>
		  <div
				style={{
				  padding: '10px 0px 0px',
				  textAlign: 'center'
				}}
			  >
				  <span
					style={{
					  fontSize: 15,
					  fontWeight: 500,
					  lineHeight: '24px',
					  display: 'inline-block',
					  color: '#000000'
					}}
				  >
				  ✓ Vay tiền online. Kỳ hạn linh hoạt 6, 9, 12, 24, 36, 48 tháng
				  </span>
				
					
		  </div>
      </div>
	  
      <div
			style={{
			  textAlign: 'center',
			  fontSize: 18,
			  fontWeight: 800,
			  textTransform: 'uppercase',
			  color: '#ff0021',
			  paddingTop: 10,
			  marginTop: 10			  
			}}
		  >
			  LỢI ÍCH KHÁCH HÀNG
		</div>
	  <div style={{ padding: '5px 10px' }}>
        <Tab
          title={'➤ Lãi suất thấp 0.9%/tháng'}
						icon={<Image
						  preview={false}
						  src={icon1}
						  style={{ height: 35, width: 35, objectFit: 'contain', margin: '0px 0px 0px 15px' }}
						/>}
        />
        <Tab
          title={'➤ Giải ngân nhanh chóng'}
          icon={<Image
						  preview={false}
						  src={icon2}
						  style={{ height: 35, width: 35, objectFit: 'contain', margin: '0px 0px 0px 15px' }}
						/>}
        />
        <Tab
          title={'➤ Không giấy tờ gốc, không thế chấp'}
          icon={<Image
						  preview={false}
						  src={icon3}
						  style={{ height: 35, width: 35, objectFit: 'contain', margin: '0px 0px 0px 15px' }}
						/>}
        />
		<Tab
          title={'➤ Thủ tục online dễ dàng 5 phút'}
          icon={<Image
						  preview={false}
						  src={icon4}
						  style={{ height: 35, width: 35, objectFit: 'contain', margin: '0px 0px 0px 15px' }}
						/>}
        />
		<Tab
          title={'➤ Không phí ẩn, không phí phát sinh'}
          icon={<Image
						  preview={false}
						  src={icon5}
						  style={{ height: 35, width: 35, objectFit: 'contain', margin: '0px 0px 0px 15px' }}
						/>}
        />
      </div>
	  


      <Button
        className="ant-btn ant-btn-default choose-btn  blink-animation"
        onClick={connectCSKH}
      >
        <Typography.Text
          style={{ color: '#fff', fontSize: 18 }}
        >
          LIÊN HỆ TƯ VẤN - HỖ TRỢ
        </Typography.Text>
      </Button>
	  
	  <div className="s-line"></div>
	  
	  <div className="footer-content">
	  <div
        style={{
          paddingTop: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
			style={{
			  padding: '0px 10px',
			}}
		>
		<Image src={tickAsset1} preview={false} style={{ maxWidth: 100 }} />
		<Image src={tickAsset2} preview={false} style={{ maxWidth: 100 }} />
		</div>
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#000',
            textAlign: 'center',
          }}
        >
          &reg; Bản quyền thuộc về công ty cổ phần Dịch vụ Tài chính
          <br />VietCredit
        </Typography.Text>
      </div>
	  </div>
    </motion.div>
  );
}
const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: '1px solid rgb(225, 0, 33)',
      }}
      onClick={onClick}
      style={{
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 15px',
        border: '1px dashed rgb(2, 112, 175)',
        borderRadius: 0,
      }}
    >
      <Typography.Text
        style={{ flex: '1 1 10%', fontSize: 15, color: 'rgb(0, 0, 0)', fontWeight: 500 }}
      >
        {title}
      </Typography.Text>
      {icon}
    </motion.div>
  );
};
